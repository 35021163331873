<template>
  <v-flex xs12 sm10 offset-sm-1 md8 offset-md-2 class="my-4">
    <v-row justify="center">
      <v-parallax
              style="border-radius:10px"
              class="elevation-4 col-11"
              height="200"
              :src="require('../../../assets/imgs/kosmos_farms.jpg')"
      >
        <v-layout
                column
                width="800px"
                class="mb-9 text-center"
        >
          <h1 class="subheading font-weight-bold">Agroinnova Farms LTD.</h1>
          <h3 class="caption font-weight-bold">All your poultry needs in one special place.</h3>
        </v-layout>
        <v-card
                class="d-flex pa-2 transparent"
                flat
        >
          <v-spacer></v-spacer>
        </v-card>
      </v-parallax>
    </v-row>
    <v-layout
            class="my-4"
            wrap
            sm12 md12>
      <v-flex sm6>
        <v-card
                style="border-radius:10px 10px 0px 0px"
                flat
                class="mx-auto trans transparent"
        >
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="headline">About Us</v-list-item-title>
              <v-list-item-subtitle class="caption"><v-icon color="orange" left>mdi-domain</v-icon>Agro Innova Farms Limited</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-card-text>
            Agroinnova Farms is an agritech company that focuses on using digital technologies to tackle problems in the agricultural value chain. Our company deploys a suite of software tools and technology-enabled platforms
            which help to streamline various processes across the agricultural sector.
          </v-card-text>
          <v-card-text class="pa-4">
            <v-img   :src="require('../../../assets/imgs/farm_image.jpg')"></v-img>
          </v-card-text>
        </v-card>
      </v-flex>


<!--      ABOUT US -->
      <v-flex sm6>
        <v-card
                style="border-radius:10px 10px 0px 0px"
                flat
                class="mx-auto trans transparent"
        >
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="headline">Our Services & Products</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-card-text>
            Agro Innova Farms is an agritech company that focuses on using digital technologies
            Our enabled platforms
          </v-card-text>
<!--          images cluster-->
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-card flat color="transparent">
                  <v-container fluid>
                    <v-row>
                      <v-col
                              v-for="n in 9"
                              :key="n"
                              class="d-flex child-flex"
                              cols="4"
                      >
                        <v-hover v-slot:default="{ hover }">
                        <v-card flat tile class="d-flex" style="border-radius:10px">
                          <v-img
                                  :src="`https://i1.wp.com/www.farmartghana.com/wp-content/uploads/2016/11/Crate-of-chicken-Eggs.jpg?resize=600%2C510&ssl=1`"
                                  :lazy-src="`https://picsum.photos/10/6?image=${n * 5 + 10}`"
                                  aspect-ratio="1"
                                  class="grey lighten-2"
                          >
                            <template v-slot:placeholder>
                              <v-row
                                      class="fill-height ma-0"
                                      align="center"
                                      justify="center"
                              >
                                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                              </v-row>
                            </template>
                            <v-expand-transition>
                              <div
                                      v-if="hover"
                                      class="d-flex
                                       transition-fast-in-fast-out
                                        green darken-3
                                         v-card--reveal
                                          headline white--text"
                                      style="height: 100%;"
                              >
                                Fresh Eggs
                              </div>
                            </v-expand-transition>
                          </v-img>
                        </v-card>
                        </v-hover>

                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-flex>


    </v-layout>

  </v-flex>
</template>
<script>
  import CategoryCard from "../../../components/Website/Category/CategoryCard"
  import PhoneRing from "../../../components/Website/PhoneRing"
  export default {
    name:'Categories',
    data() {
      return {
        title: 'Eggs Category',
        expanded: true,
        rightDrawer: false,
        featured_models:[],
        grade: [
          {id:1,text:'Large'},
          {id:2,text:'Small'},

        ],
        pack_size: [
          { id:1,text: 'Cartons' },
          { id:2,text: 'Crates' },
          { id:3,text: 'Boxes' },

        ],
        egg_type: [
          { id:1, text: 'Quail' },
          { id:2, text: 'Chicken Eggs' },
        ],
        egg_color:[
          {id:1, text:'Brown'},
          {id:2, text:'White'},
        ],
        best_match: [
          { text: 'Top Selling' },
          { text: 'Newest' },
          { text: 'Oldest' },
          { text: 'Lower price' },
          { text: 'Higher price' }
        ],
        toggle_exclusive: 2,
        toggle_multiple: [1, 2, 3]
      }
    },
    mounted(){
    }
    ,
    methods:{

    },
    components:{
      CategoryCard,
      PhoneRing
    },
  }
</script>

<style lang="scss" scoped>
  .porto{
    background:rgba(255,255,255,0.6)
  }
  .dud{
    color: #fff;
    font-size: 90px;
    font-weight:bold;
    line-height: 1em;
    margin: 0;
    text-align: left;
    transform: translateY(-50%);
    width: 100%;
  }

  .v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .5;
    position: absolute;
    width: 100%;
  }
  .social-fo li a {
    width: 35px;
    height: 35px;
    display: block;
    background: #3B5998;
    border-radius: 100px;
    color: #fff;
    line-height: 2.5em;
  }
  .social-fo li a:hover {
    transform:rotate(-360deg);
  }
  .social-fo li a.twi {
    background: #4099FF;
  }
  .social-fo li a.pin {
    background: #CD2830;
  }
  .social-fo li a.dri {
    background:#E35E90;
  }

</style>
